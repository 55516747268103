<template>
  <el-card>
    <div slot="header">
      <h2>{{$t('page.console.menu.home_settings')}}</h2>
    </div>

    <el-form ref="form" :model="form" label-width="80px" label-position="left">
      <el-form-item :label="$t('lang')">
        <el-radio v-model="lang" label="zh-CN">中文</el-radio>
        <el-radio v-model="lang" label="en-US">English</el-radio>
      </el-form-item>

      <el-form-item label="Banner">
        <el-popover
            placement="top-start"
            width="200"
            trigger="hover">
          <el-image :src="form.banner"></el-image>
          <el-input slot="reference" v-model="form.banner" size="small" clearable>
            <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('banner')"></el-button>
          </el-input>
        </el-popover>
      </el-form-item>

      <!--<el-form-item label="Banner">
        <el-table :data="form.banners.list" border>
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="image">
            <template slot-scope="scope">
              <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover">
                <el-image :src="scope.row.image"></el-image>
                <el-input slot="reference" v-model="scope.row.image" size="small" clearable>
                  <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('banners', scope.$index)"></el-button>
                </el-input>
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('banners', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('banners')">{{$t('button.add')}}</el-button>
      </el-form-item>-->

      <!-- 公司简介 -->
      <el-form-item :label="$t('page.goldenSun.company_profile')">
        <!--<el-form-item :label="$t('page.console.menu.title')" class="w60">
          <el-input v-if="lang == 'zh-CN'" size="mini" v-model="form.solution.title"></el-input>
          <el-input v-else size="mini" v-model="form.solution.title"></el-input>
        </el-form-item>-->
        <el-col :span="12" v-if="lang == 'zh-CN'">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="form.company_profile.desc"></el-input>
        </el-col>
        <el-col :span="12" v-else>
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="form.company_profile.en_desc"></el-input>
        </el-col>
      </el-form-item>

      <!-- 愿景使命 -->
      <el-form-item :label="$t('page.goldenSun.vision_mission')">
        <el-col :span="12" v-if="lang == 'zh-CN'">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="form.vision_mission.desc"></el-input>
        </el-col>
        <el-col :span="12" v-else>
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="form.vision_mission.en_desc"></el-input>
        </el-col>
      </el-form-item>

      <!-- 发展历程 -->
      <el-form-item :label="$t('page.goldenSun.development_history')">

        <div v-for="(list, i) in form.history.list" class="mt20 history">
          <el-button type="danger" size="mini" @click="remove('history', i)">{{$t('button.del')}}</el-button>
          <el-form-item :label="$t('page.goldenSun.year') + `:`">
            <el-col :span="12" v-if="lang == 'zh-CN'">
              <el-input size="small" v-model="form.history.list[i].title"></el-input>
            </el-col>
            <el-col :span="12" v-else>
              <el-input size="small" v-model="form.history.list[i].en_title"></el-input>
            </el-col>
          </el-form-item>

          <el-table :data="form.history.list[i].item" border>
            <el-table-column type="index" label="#"></el-table-column>
            <el-table-column label="content" v-if="lang == 'zh-CN'">
              <template slot-scope="scope">
                <el-input v-model="scope.row.content" size="small" clearable></el-input>
              </template>
            </el-table-column>

            <el-table-column label="en_content" v-else>
              <template slot-scope="scope">
                <el-input v-model="scope.row.en_content" size="small" clearable></el-input>
              </template>
            </el-table-column>

            <el-table-column :label="$t('action')">
              <template slot-scope="scope">
                <el-button type="danger" size="mini" @click="removeHistory('history', i, scope.$index)">{{$t('button.del')}}</el-button>
              </template>
            </el-table-column>
          </el-table>

          <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrsHistory('history', i)">{{$t('button.add')}}</el-button>
        </div>

        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('history')">{{$t('button.add')}}</el-button>
      </el-form-item>



      <!-- 企业文化 -->
      <el-form-item :label="$t('page.goldenSun.business_culture')">
        <el-table :data="form.business_culture.list" class="mt20" border>
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title" v-if="lang == 'zh-CN'" size="small" clearable></el-input>
              <el-input v-model="scope.row.en_title" v-else size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="desc">
            <template slot-scope="scope">
              <el-input v-model="scope.row.desc" v-if="lang == 'zh-CN'" size="small" clearable></el-input>
              <el-input v-model="scope.row.en_desc" v-else size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('business_culture', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('business_culture')">{{$t('button.add')}}</el-button>
      </el-form-item>


      <!-- 人才理念 -->
      <el-form-item :label="$t('page.goldenSun.talent_concept')">
        <el-col :span="12" v-if="lang == 'zh-CN'">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="form.talent_concept.desc"></el-input>
        </el-col>
        <el-col :span="12" v-else>
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="form.talent_concept.en_desc"></el-input>
        </el-col>
      </el-form-item>

      <!-- 人才理念 -->
      <el-form-item :label="$t('page.goldenSun.social_responsibility')">
        <el-col :span="12" v-if="lang == 'zh-CN'">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="form.social_responsibility.desc"></el-input>
        </el-col>
        <el-col :span="12" v-else>
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="form.social_responsibility.en_desc"></el-input>
        </el-col>
      </el-form-item>



      <el-form-item>
        <el-button class="right mt20" size="mini" type="primary" @click="onSave">{{$t('button.save')}}</el-button>
      </el-form-item>
    </el-form>


    <el-dialog :visible.sync="dialogVisible" :title="$t('tips.select')">
      <select-image
          :multiple="false"
          :active="false"
          @select="getImages"
          @close="dialogVisible = false">
      </select-image>
    </el-dialog>

    <el-dialog :visible.sync="selectPostDialogVisible" :title="$t('tips.select')">
      <select-post></select-post>
    </el-dialog>
  </el-card>
</template>

<script>
import selectImage from '@/components/Images';
import selectPost from '@/view/os/console/SelectPost';
import {updateOption, getOption} from '@/api/os/admin';

export default {
  data() {
    return {
      optionName: 'golden_sun',
      lang: 'zh-CN',

      dialogVisible: false,
      form: {
        company_profile: {
          // title: '',
          // en_title: '',
          desc: '',
          en_desc: ''
        },
        vision_mission: {
          desc: '',
          en_desc: ''
        },
        history: {
          list: [
            {
              item: [
                {
                  content: '',
                  en_content: ''
                }
              ],
              title: '',
            }
          ]
        },
        business_culture: {
          list: []
        },
        talent_concept: {
          desc: '',
          en_desc: ''
        },
        social_responsibility: {
          desc: '',
          en_desc: ''
        },
        banner: '',
        banners: {
          list: [],
          title: '',
          desc: ''
        },
        solution: {
          list: [],
          title: '',
          desc: ''
        },
        product: {
          list: [],
          title: '',
          desc: ''
        },
        dynamics: {
          list: [],
          title: '',
          desc: ''
        },
        activityImages: {
          list: [],
          title: '',
          desc: ''
        }
      },
      index: 0,
      type: '',
      selectPostDialogVisible: false
    }
  },
  components: {
    selectImage,
    selectPost
  },
  created() {
    this.get()
  },
  methods: {
    addAttrs(type) {
      if(!this.form[type]) {
        let data = {
          list: [],
          title: '',
          desc: ''
        }
        this.$set(this.form, type, data);
      }
      let info = {};
      if(type == 'history') {
        info = {
          item: [
              {
                content: '',
                en_content: ''
              }
          ],
          title: '',
        }
      }

      return this.form[type].list.push(info)
    },
    addAttrsHistory(type, index) {
      this.form[type].list[index].item.push({
        content: '',
        en_content: ''
      })
    },
    removeHistory(type, index, itemIndex) {
      this.form[type].list[index].item.splice(itemIndex, 1)
    },
    remove(type, index) {
      this.form[type].list.splice(index, 1)
    },
    mediaUpload(type,index = 0) {
      this.dialogVisible = true
      this.index = index
      this.type = type
    },
    getImages(data) {
      if(this.type == 'banner') {
          // this.$set(this.form[this.type].list[this.index], 'image', data)
         this.form[this.type] = data
      } else {
        this.$set(this.form[this.type].list[this.index], 'image', data)
      }
      // this.form.banner[this.index].image = data
      this.dialogVisible = false
    },
    get() {
      getOption({
        name: this.optionName,
      }).then(res => {
        if(res.data.data) {
          this.form = res.data.data
        }
      })
    },
    onSave() {
      updateOption({
        name: this.optionName,
        value: this.form
      }).then(res => {
        this.$message.success(this.$t('tips.success'))
      })
    }
  }
}
</script>

<style>
.history{
  border: 1px dashed #c5c5c5;
  padding: 30px 20px;
}
</style>